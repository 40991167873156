import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ApiService from '../../../services/ApiService'
import {
    setSelectedMenu,
    setSelectedSubmenu
} from '../../../actions/index'
import SupportPanelAdmin from './partials/SupportPanelAdmin'
import CollaboratorLogos from './partials/CollaboratorLogos'
import SupportByLogos from './partials/SupportedByLogos'

const SupportAdministrator = (props) => {

    const { support } = props

    const [temporalPage, setTemporalPage] = useState(support);
    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState({ title: "", message: "", color: "" });

    useEffect(() => {
        window.scrollTo(0, 0)
        if(props.selectedMenu !== 'Admin') props.dispatchSetSelectedMenu('Admin')
        props.dispatchSetSelectedSubmenu('SupportsAdministrator')
    },[])

    useEffect(() => {
        setTemporalPage(support);
    }, [support]);

    const handleSaveTraductions = async (newTaductions) => {
        const newPage = Object.assign({}, support);
        newPage.Traductions = newTaductions;
        newPage.nameOfThePage = 'Supports';
        try {
          const response = await ApiService.updatePage(newPage);
          if (response) {
            setToastContent({ title: "Success", message: "Page updated successfully", color: "success" });
            setShowToast(true);
          } else {
            setToastContent({ title: "Error", message: "Failed to update the page", color: "danger" });
            setShowToast(true);
          }
        } catch (error) {
          setToastContent({ title: "Error", message: "An error occurred while updating the page", color: "danger" });
          setShowToast(true);
        }
    };

    const handleSaveCollaboratorsImages = async (images) => {
        const newPage = Object.assign({}, support);
        newPage.Images.Collaborators = images;
        newPage.nameOfThePage = 'Supports';
        try {
          const response = await ApiService.updatePage(newPage);
          if (response) {
            setToastContent({ title: "Success", message: "Page updated successfully", color: "success" });
            setShowToast(true);
          } else {
            setToastContent({ title: "Error", message: "Failed to update the page", color: "danger" });
            setShowToast(true);
          }
        } catch (error) {
          setToastContent({ title: "Error", message: "An error occurred while updating the page", color: "danger" });
          setShowToast(true);
        }
      };

      const handleSaveSupportImages = async (images) => {
        const newPage = Object.assign({}, support);
        newPage.Images.SupportedBy = images;
        newPage.nameOfThePage = 'Supports';
        try {
          const response = await ApiService.updatePage(newPage);
          if (response) {
            setToastContent({ title: "Success", message: "Page updated successfully", color: "success" });
            setShowToast(true);
          } else {
            setToastContent({ title: "Error", message: "Failed to update the page", color: "danger" });
            setShowToast(true);
          }
        } catch (error) {
          setToastContent({ title: "Error", message: "An error occurred while updating the page", color: "danger" });
          setShowToast(true);
        }
      };

    return (
        <div>
            {/* <SupportPanelAdmin traductions={temporalPage.Traductions} handleSaveTraductions={handleSaveTraductions} />
            <CollaboratorLogos collaborators={temporalPage.Images.Collaborators} handleSaveCollaboratorsImages={handleSaveCollaboratorsImages} />
            <SupportByLogos supportedBy={temporalPage.Images.SupportedBy} handleSaveSupportImages={handleSaveSupportImages} />
            <div className={`toast align-top bg-${toastContent.color} ${showToast ? 'show' : ''}`} role="alert" aria-live="assertive" aria-atomic="true" style={{position: 'fixed', top: '20px', right: '20px', zIndex: 1050}}>
                <div className="toast-header">
                <strong className="me-auto">{toastContent.title}</strong>
                <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowToast(false)}></button>
                </div>
                <div className="toast-body">
                {toastContent.message}
                </div>
            </div> */}
        </div>
    )
}

const mapDispatchToProps = dispatch => (
    {
        dispatchSetSelectedMenu: value => dispatch(setSelectedMenu(value)),
        dispatchSetSelectedSubmenu: value => dispatch(setSelectedSubmenu(value))
    }
)

const mapStateToProps = state => ({
    selectedMenu: state.selectedMenu,
    languageWeb: state.languageWeb,
    support: state.pages.Supports
})

export default connect(mapStateToProps, mapDispatchToProps)(SupportAdministrator)